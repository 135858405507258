<template>
<b-card :title="$t('customers')">
  <b-row>
    <b-col md="4" class="my-1">
      <b-form-group :label="$t('filter')" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" type="search" id="filterInput" :placeholder="$t('search')"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('clear') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
    </b-col>
    <b-col md="8" class="my-1">
      <div class="text-right">
        <router-link :to="{ name: 'customers.create' }" class="btn btn-sm btn-primary">{{ $t('add') }}</router-link>
      </div>
    </b-col>
  </b-row>
  <b-table
    responsive
    striped
    hover
    show-empty
    sort-icon-left
    :filter="filter"
    :items="items"
    :fields="fields"
    :current-page="currentPage"
    :per-page="0" @filtered="onFiltered">
    <template v-slot:cell(actions)="row">
        <b-button size="sm" class="mr-1" variant="primary" @click="info(row.item, row.index, $event.target)">
          <fa icon="eye" />
        </b-button>
        <router-link :to="{ name: 'customers.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning mr-1"><fa icon="pencil-alt" /></router-link>
        <b-button size="sm" class="mr-1" variant="danger" @click="removeItem(row.item.id)">
          <fa icon="trash" />
        </b-button>
    </template>
  </b-table>
  <!-- Pagination -->
  <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
  <!-- Modal info -->
  <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
    <div v-html="infoModal.content"></div>
  </b-modal>
</b-card>
</template>
<script>
import axios from 'axios'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('users') }
    },
    data: () => ({
      items: [],
      fields: [
        {
          key: 'first_name',
          label: 'Ім"я'
        },
        {
          key: 'group',
          label: 'Група',
          sortable: true
        },
        {
          key: 'last_name',
          label: 'Фамілія',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'phone',
          label: 'Телефон',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Додано',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Дії'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      editModal: {
        id: 'edit-modal',
        title: ''
      }
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
    },
    methods: {
      async fetchData () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'customers?page=' + this.currentPage)
        this.items = data.data
        this.currentPage = data.meta.pagination.current_page
        this.perPage = data.meta.pagination.per_page
        this.totalItems = data.meta.pagination.total
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'customers/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      info (item, index, btn) {
        this.infoModal.title = 'Інформація по клієнту'
        let html = '<ul class="list-group">'
            html += '<li class="list-group-item"><strong>Імя:</strong> ' + item.first_name + '</li>'
            html += '<li class="list-group-item"><strong>Фамілія:</strong> ' + item.last_name + '</li>'
            html += '<li class="list-group-item"><strong>Побатькові:</strong> ' + item.surname + '</li>'
            html += '<li class="list-group-item"><strong>Телефон:</strong> ' + item.phone + '</li>'
            html += '<li class="list-group-item"><strong>Е-мейл:</strong> ' + item.email + '</li>'
            html += '<li class="list-group-item"><strong>Група:</strong> <span class="badge badge-info">' + item.group + '</span></li>'
            html += '<li class="list-group-item"><strong>Отримувач:</strong> <span class="badge badge-success">' + ((item.recipient_type === 0) ? 'Фіз. лице' : 'Юр.лице') + '</span></li>'
            html += '<li class="list-group-item"><strong>Місто:</strong> ' + item.city + '</li>'
            html += '<li class="list-group-item"><strong>Адреса:</strong> ' + item.address + '</li>'
            html += '<li class="list-group-item"><strong>Компанія:</strong> ' + item.company + '</li>'
            html += '<li class="list-group-item"><strong>Додоно:</strong> ' + item.created_at + '</li>'
            html += '<li class="list-group-item"><strong>Доп. інформація:</strong> ' + item.description + '</li>'
            html += '</ul>'
        this.infoModal.content = html
        this.$root.$emit('bv::show::modal', this.infoModal.id, btn)
      },
      resetInfoModal () {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered (filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
